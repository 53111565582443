<div id="userArea" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" mat-ripple>
  <div id="userMeta">
    <h3>{{ userService.getUserName() }}</h3>
    <mat-icon>{{ menuTrigger.menuOpen ? 'expand_less' : 'expand_more' }}</mat-icon>
    <mat-menu #menu="matMenu" xPosition="before">
      <button style="min-width: 220px" (click)="logout()" class="mat-menu-item" tabindex="-1">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>
