import { Injectable, Injector } from '@angular/core';
import { OAuthInfoEvent, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';
import { concatMap, distinctUntilChanged, filter, map, mapTo, shareReplay, startWith, take, tap } from 'rxjs/operators';
import { BehaviorSubject, from, of } from 'rxjs';
import { UserService } from './user.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  constructor(
    private oAuthServer: OAuthService,
    private injector: Injector,
    private userService: UserService,
    private loadingService: LoadingService
  ) {
    this.loadingService.setDoneLoading(false);
    this.oAuthServer.configure(environment.authConfig);
    this.oAuthServer.loadDiscoveryDocumentAndLogin();

    this.oAuthServer.events
      .pipe(filter(e => ['discovery_document_loaded'].includes(e.type)))
      .subscribe(e => this.discoveryDocumentedLoaded(e));

    this.oAuthServer.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.tokenReceived(e));

    this.oAuthServer.events.pipe(filter(e => ['session_terminated'].includes(e.type))).subscribe(e => this.logout());
  }

  public logout(revoke: boolean = true) {
    this.loadingService.setDoneLoading(false);
    this.isAuthenticatedSubject$.next(false);
    this.userService._userSubject.next(null);
    localStorage.removeItem('user');
    localStorage.removeItem('ucc');
    localStorage.removeItem('recentUrl');
    this.oAuthServer.logOut();
  }

  private tokenReceived(e: any): void {
    this.oAuthServer.loadUserProfile().then(_ => {
      this.setIsAuthenticated();
    });
  }

  private discoveryDocumentedLoaded(e: any): void {
    if (!e.info) {
      return;
    }

    this.setIsAuthenticated();
  }

  private setIsAuthenticated(): void {
    let isAuthenticated = this.oAuthServer.hasValidAccessToken() && this.oAuthServer.hasValidIdToken();
    this.isAuthenticatedSubject$.next(isAuthenticated);
    if (isAuthenticated) {
      this.loadingService.setDoneLoading(true);
    }
  }
}
