import { ErrorInterceptor } from './share/intercept/error.interceptor';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AUTH_CONFIG, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      easing: 'ease-in',
      easeTime: 500,
      tapToDismiss: true,
      closeButton: true,
    }),
  ],
  providers: [
    { provide: OAuthModuleConfig, useValue: environment.oAuthModuleConfig },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
