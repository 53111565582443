<mat-sidenav-container [style.height.vh]="100" [hasBackdrop]="sideNav.isExpanded">
  <mat-sidenav
    #drawer
    mode="side"
    disableClose="true"
    opened
    [style.backgroundColor]="'var(--cf-active-freight-blue1)'"
  >
    <app-sidenav #sideNav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="inner-content" fxLayout="column">
    <app-nav></app-nav>
    <router-outlet class="outlet"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
