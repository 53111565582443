import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { concatMap, filter, map, mapTo, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private isAuthenticated: boolean;

  constructor(private authService: AuthService, private loadingService: LoadingService) {
    this.authService.isAuthenticated$.subscribe(result => {
      this.isAuthenticated = result;
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    localStorage.setItem('recentUrl', state.url);

    return this.loadingService.isDoneLoading$.pipe(filter(isDone => isDone)).pipe(map(_ => this.isAuthenticated));
  }
}
