import { Component, OnInit } from '@angular/core';
import { filter, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  private isAuthenticated: boolean;
  private isAuthenticatedSub: Subscription;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingService
  ) {
    this.isAuthenticatedSub = this.authService.isAuthenticated$.subscribe(result => {
      this.isAuthenticated = result;
    });
  }

  ngOnInit(): void {
    this.loadingService.isDoneLoading$.pipe(filter(isDone => isDone)).subscribe(_ => {
      if (this.isAuthenticated) {
        this.userService.getUserInfo().subscribe(
          _ => {},
          error => {
            console.error('getUserInfo error:', error);
          }
        );
      }
    });
  }
}
