import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(req => {
        if (!req.ok) {
          switch (req.status) {
            case 400:
              this.toastr.warning(req.error, 'Bad request, please try again', {
                timeOut: 10000,
              });
              return throwError(req);
            case 401:
              if (!window.location.href.includes('login')) {
                this.toastr.warning('User authentication expired, please log back in.', 'User Unauthenticated', {
                  timeOut: 10000,
                });
                this.router.navigateByUrl('unauth/logout');
                return next.handle(req);
              } else {
                // Specialized error handling located in login component
                return next.handle(req);
              }
            case 403:
              this.toastr.warning(
                'User requested a forbidden action, please log back in to verify user account permissions.',
                'Forbidden Request Detected',
                { timeOut: 10000 }
              );
              this.router.navigateByUrl('u/logout');
              return next.handle(req);
            case 404:
              return throwError(req);
            case 500:
              this.toastr.warning(
                'A 500 error was encountered, please report this event to an administrator',
                'Server Error',
                {
                  timeOut: 5000,
                }
              );
              return next.handle(req);
            case 503:
              this.toastr.warning(
                'A 503 error was encountered, please report this event to an administrator',
                'Service Unavailable',
                { timeOut: 5000 }
              );
              return next.handle(req);
            default:
              console.error(`Unexpected error code received: ${req.status}`);
              return next.handle(req);
          }
        }
        return next.handle(req);
      })
    );
  }
}
