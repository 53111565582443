// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AuthConfig, OAuthModuleConfig } from 'angular-oauth2-oidc';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.

const authConfig: AuthConfig = {
  issuer: 'https://sso-dev.clearfreight.com',
  redirectUri: 'https://dev-admin-cf.clearfreight.com',
  clientId: 'clearfreight_spa',
  responseType: 'code',
  scope: 'IdentityServerApi offline_access',
  showDebugInformation: true,
  // disablePKCE: true,
  requireHttps: true,
  timeoutFactor: 0.01,
  postLogoutRedirectUri: 'https://dev-admin-cf.clearfreight.com',
};

const oAuthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [
      'https://sso-dev.clearfreight.com',
      'https://dev-admin-cf.clearfreight.com',
      'https://dev-site.clearfreight.com/api/',
    ],
    sendAccessToken: true,
  },
};

export const environment = {
  production: true,
  authConfig,
  oAuthModuleConfig,
  websiteUrl: 'https://dev-site.clearfreight.com/',
  apiUrl: 'https://clearview-dev.clearfreight.com/api/api/',
  cfApiUrl: 'https://dev-site.clearfreight.com/api/',
  clearViewUrl: 'https://clearview-dev.clearfreight.com',
  containerBlogImageName: 'dev-blogimage',
  containerBlogVideoName: 'dev-blogvideo',
  azureBlobUrl: 'https://clrfwebservicestorage.blob.core.windows.net',
};
