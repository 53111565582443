import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../share/model/user.model';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public _userSubject = new BehaviorSubject<User | null>(null);

  public get userValue(): User | null {
    return this._userSubject.value;
  }

  public get getHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    });
  }

  public headers: HttpHeaders;

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) {
    let storageUser = null;
    if (localStorage.getItem('user')) {
      storageUser = JSON.parse(localStorage.getItem('user') || '{}') as User;

      if (new User(storageUser).timeUntilExpired() < 10) {
        // If < 10 minutes left on token, treat as empty storage.
        storageUser = null;
      }
    } else {
      storageUser = null;
    }

    this._userSubject = new BehaviorSubject<User | null>(storageUser ? new User(storageUser) : null);

    this._userSubject.subscribe(() => {
      if (this.userValue) {
        localStorage.setItem('user', JSON.stringify(this.userValue));
      }
    });
  }

  public getUserInfo(): Observable<void> {
    return this.httpClient.get<User>(`${environment.apiUrl}user/info`, { headers: this.getHeaders }).pipe(
      map(userResponse => {
        const user = new User(userResponse);
        user.lastLogin = new Date().toISOString();
        user.org = user.org.toString();
        this._userSubject.next(user);
      })
    );

    // return this.httpClient.get<User>(`${environment.apiUrl}user/info`).pipe(
    //   map(userResponse => {
    //     const user = new User(userResponse);
    //     user.lastLogin = new Date().toISOString();
    //     user.org = user.org.toString();
    //     this._userSubject.next(user);
    //   })
    // );
  }

  public getUserInitials(): string | null {
    return this.userValue ? this.userValue?.firstName?.substr(0, 1) + this.userValue?.lastName?.substr(0, 1) : null;
  }

  public getUserName(): string {
    if (!this.userValue) {
      return '';
    }
    let userName = `${this.userValue?.firstName} ${this.userValue?.lastName}`;
    if (userName.length > 20) {
      userName = this.userValue?.firstName;
    }
    return userName;
  }
}
