import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @HostListener('click', ['$event'])
  onClickProp(e: Event) {
    e.stopPropagation();
  }

  constructor(
    private authService: AuthService,
    public userService: UserService
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout()
  }


}
