import * as moment from 'moment';

export class User {
  private _phone: string;
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;

  public get phone(): string {
    return this.convertTel(this._phone);
  };

  public set phone(val: string) {
    this._phone = val;
  }

  public lastLogin: string;
  public lastOrg: string;
  public org: string;
  public createDate: string;
  public inviteSentDate: string;
  public loginDate: string;
  public deleteDate: string;
  public siteAdministrator: boolean;
  public clearFreightUsers: boolean;
  public imp: string;
  public previousLoginDate: Date;
  public legacyUserId: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  public timeUntilExpired(): number {
    if (!this.lastLogin) {
      return 0;
    }
    const expire = moment(
      new Date(new Date(this.lastLogin).toISOString()).getTime() +
      1000 * 60 * 60 * (this.siteAdministrator ? 6 : 24)
    );
    const now = moment(new Date(new Date().toISOString()).getTime());
    return expire.diff(now, 'minutes');
  }

  private convertTel(tel: string): string {
    if (!tel) {
      return '';
    }
    const value = tel.toString().trim().replace(/\D/g, '');
    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let countryCode = '+';
    let cityCode;
    let telNumber;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        countryCode += '1';
        cityCode = value.slice(0, 3);
        telNumber = value.slice(3);
        break;
      case 11: // +CPPP####### -> CCC (PP) ###-####
        countryCode += value[0];
        cityCode = value.slice(1, 4);
        telNumber = value.slice(4);
        break;
      case 12: // +CCCPP####### -> CCC (PP) ###-####
        countryCode += value.slice(0, 3);
        cityCode = value.slice(3, 5);
        telNumber = value.slice(5);
        break;
      default:
        return tel;
    }
    if (countryCode === '+1') {
      countryCode = '';
    }
    telNumber = telNumber.slice(0, 3) + '-' + telNumber.slice(3);
    return (`${countryCode} (${cityCode}) ${telNumber}`).trim();
  }
}
