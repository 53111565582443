<div class="logo-container">
  <img id="logo" class="pointer" tabindex="0" src="https://clearview.clearfreight.com/assets/images/logo-un.png" />
</div>
<div class="nav-container">
  <div>
    <a [routerLink]="'/posts/list/comm'" [class]="{ link: true, 'current-route': currentRoute === '/posts/list/comm' }">
      <mat-icon>article</mat-icon>
      <h1 *ngIf="isExpanded">Posts</h1>
    </a>
    <a [routerLink]="'/posts/unlisted/comm'" [class]="{ link: true, 'current-route': currentRoute === '/posts/unlisted/comm' }">
      <mat-icon>view_list</mat-icon>
      <h1 *ngIf="isExpanded">Unlisted Posts</h1>
    </a>
  </div>
  <div>
    <a [routerLink]="'/posts/list/stats'" [class]="{ link: true, 'current-route': currentRoute === '/posts/list/stats' }">
      <mat-icon>pie_chart</mat-icon>
      <h1 *ngIf="isExpanded">Port Stats</h1>
    </a>
    <a [routerLink]="'/posts/unlisted/stats'" [class]="{ link: true, 'current-route': currentRoute === '/posts/unlisted/stats' }">
      <mat-icon>view_list</mat-icon>
      <h1 *ngIf="isExpanded">Unlisted Posts</h1>
    </a>
  </div>
  <div>
    <a [routerLink]="'/posts/list/industry'" [class]="{ link: true, 'current-route': currentRoute === '/posts/list/industry' }">
      <mat-icon>ballot</mat-icon>
      <h1 *ngIf="isExpanded">Industry News</h1>
    </a>
    <a [routerLink]="'/posts/unlisted/industry'" [class]="{ link: true, 'current-route': currentRoute === '/posts/unlisted/industry' }">
      <mat-icon>view_list</mat-icon>
      <h1 *ngIf="isExpanded">Unlisted Posts</h1>
    </a>
  </div>
  <div>
    <a [routerLink]="'/posts/list/brokerage'" [class]="{ link: true, 'current-route': currentRoute === '/posts/list/brokerage' }">
      <mat-icon>domain</mat-icon>
      <h1 *ngIf="isExpanded">Brokerage Updates</h1>
    </a>
    <a [routerLink]="'/posts/unlisted/brokerage'" [class]="{ link: true, 'current-route': currentRoute === '/posts/unlisted/brokerage' }">
      <mat-icon>view_list</mat-icon>
      <h1 *ngIf="isExpanded">Unlisted Posts</h1>
    </a>
  </div>
</div>
<mat-divider class="divider" [style.borderTopColor]="'var(--cf-secondary-blue4)'"></mat-divider>
<div class="nav-container">
  <div>
    <a class="link" href="{{ redirectLink }}" target="_blank">
      <mat-icon>directions_boat</mat-icon>
      <h1 *ngIf="isExpanded">ClearView</h1>
    </a>
  </div>
  <mat-divider class="divider" [style.borderTopColor]="'var(--cf-secondary-blue4)'"></mat-divider>
  <div class="button">
    <button (click)="isExpanded = !isExpanded">
      <mat-icon [ngClass]="{ close: isExpanded, open: !isExpanded }">
        {{ isExpanded ? 'arrow_back_ios' : 'arrow_forward_ios' }}
      </mat-icon>
    </button>
  </div>
</div>
