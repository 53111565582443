import {NgModule} from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import {ShareModule} from "../share/share.module";
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { NavComponent } from './layout/nav/nav.component';


@NgModule({
  declarations: [
    AuthComponent,
    SidenavComponent,
    NavComponent
  ],
  imports: [
    ShareModule,
    AuthRoutingModule
  ],
  providers: []
})
export class AuthModule {}
